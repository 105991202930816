<div class="card" style="height: 100%; padding: 50px 50px 65px 50px;">
    <aw-wizard class="wizard-design" >
        <form style="width: 100%" [formGroup]="cartForm">
            <aw-wizard-step (stepEnter)="onStepEnter($event,CART_STEPS.BundleDetails);" style="margin-top: 40px; min-height: 400px;" stepTitle="Product(s)"></aw-wizard-step>
            <aw-wizard-step (stepEnter)="onStepEnter($event,CART_STEPS.RequiredInfo)" style="margin-top: 40px" stepTitle="Location">
                <div *ngIf="!requiredInfoCompleted">
                    <div fxLayout="column">
                        <div *ngIf="!isOptionSelected">
                            <div fxLayout="column"  fxLayoutAlign="space-evenly center">
                                <div fxLayout="column" fxLayoutAlign="start center">
                                    <div *ngIf="this.userRoles.includes(1) || this.userRoles.includes(4)" fxLayout="column" fxLayoutAlign="center start" style="width: 100% !important;">
                                        <p>Please select a Client Record:</p>
                                        <ng-select style="width: 300px !important;" [items]="this.holdClients" bindLabel="fullName" bindValue="id" (change)="onClientChange($event);"></ng-select>
                                    </div>
                                    <ng-container>
                                        <div style="width: 100%; margin-top: 10px; border-bottom: 2px solid grey;" fxLayout="column" fxLayoutAlign="center start">
                                            <strong style="font-size: 24px; margin-left: 1.25vw;">Create or Reactivate Previous Campaigns</strong>
                                            <p style="margin-left: 1.25vw;">Locations without active subscriptions are eligble to be reactivated.</p>
                                        </div>                                    
                                        <div style="width: 70vw !important;" fxLayout="row wrap" fxLayoutAlign="start center">
                                            <ng-container *ngFor="let p of allMyClients; let i=index;">
                                                <ng-container *ngFor="let z of mapKeys.get(p.id); let k=index;">
                                                    <button type="button" *ngIf="checkCampPlatforms(z) && (z !== '')" (click)="selectedCampaign(p.id, z);" fxLayout="column" fxLayoutAlign="center center" style="text-align: center; margin-top: 30px; margin-right: 1.25vw; margin-left: 1.25vw; width: 15vw !important;">
                                                        <div fxLayout="column" fxLayoutAlign="end center" [ngStyle]="{'background-color': (checkCampPlatforms(z)) ? 'transparent' : 'lightgrey'}" style="width: 15vw; padding-left: 20px !important; padding-right: 20px !important; min-height: 250px;" class="block">
                                                            <div style="text-align: center; font-weight: bold; font-size: large;">
                                                                {{z}}
                                                            </div>
                                                            <div [ngClass]="(checkCampPlatforms(z)) ? 'okay-camp' : 'bad-camp'">
                                                                <mat-icon style="height: 35px; width: 35px; font-size: 35px;">location_city</mat-icon>
                                                            </div>
                                                            <button class="black-button" [ngStyle]="{'color': (checkCampPlatforms(z)) ? 'white' : 'darkgrey'}">Select Location</button>
                                                        </div>
                                                    </button>
                                                </ng-container>
                                            </ng-container>
                                            <div fxLayout="column" fxLayoutAlign="center center" style="text-align: center;margin-top: 10px;">
                                                <button type="button" (click)="isNewCampaign = true;isOptionSelected = true;" fxLayout="column" fxLayoutAlign="center center" style="text-align: center; margin-top: 30px; margin-right: 1.25vw; margin-left: 1.25vw; width: 15vw !important;">
                                                    <div fxLayout="column" fxLayoutAlign="space-between center" [ngStyle]="{'background-color': 'transparent'}" style="width: 15vw; padding-left: 20px !important; padding-right: 20px !important; min-height: 250px;" class="block">
                                                        <div style="text-align: center;font-weight: bold;font-size: large;">
                                                            Create a New Campaign
                                                        </div>
                                                        <div style="display: inline-flex; align-items: center; justify-content: center; width: 75px; height: 75px; border-radius: 50%; border: 2px solid grey; color: black; background-color: transparent; cursor: pointer;">
                                                            <mat-icon style="color: black; font-size: 35px; width: 35px; height: 35px;">add_business</mat-icon>
                                                        </div>
                                                        <button class="orange-button" [ngStyle]="{'color': 'white'}">Create Location</button>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div style="width: 100%; margin-top: 40px; border-bottom: 2px solid grey;" fxLayout="column" fxLayoutAlign="center start">
                                            <strong style="font-size: 24px; margin-left: 1.25vw; margin-top: 10px;">Currently Active Locations</strong>
                                            <p style="margin-left: 1.25vw;">Locations with active subscriptions need to be cancelled to start a new platform. To edit the ad spend for these locations, go to the "Subscriptions" tab in the menu.</p>
                                        </div>
                                        <div style="width: 70vw !important; margin-bottom: 40px;" fxLayout="row wrap" fxLayoutAlign="start center">
                                            <ng-container *ngFor="let p of allMyClients; let i=index;">
                                                <ng-container *ngFor="let z of mapKeys.get(p.id); let k=index;">
                                                    <button type="button" *ngIf="!checkCampPlatforms(z) && (z !== '')" fxLayout="column" fxLayoutAlign="center center" style="text-align: center; margin-top: 30px; margin-right: 1.25vw; margin-left: 1.25vw; width: 15vw !important;">
                                                        <div fxLayout="column" fxLayoutAlign="end center" [ngStyle]="{'background-color': (checkCampPlatforms(z)) ? 'transparent' : 'lightgrey'}" style="width: 15vw !important; padding-left: 20px !important; padding-right: 20px !important; min-height: 250px;" class="block">
                                                            <div style="text-align: center; font-weight: bold; font-size: large;">
                                                                {{z}}
                                                            </div>
                                                            <div [ngClass]="(checkCampPlatforms(z)) ? 'okay-camp' : 'bad-camp'">
                                                                <mat-icon style="height: 35px; width: 35px; font-size: 35px;">location_city</mat-icon>
                                                            </div>
                                                            <div style="height: 35px;"></div>
                                                            <!-- <button class="black-button" [ngStyle]="{'color': (checkCampPlatforms(z)) ? 'white' : 'darkgrey'}">Select Location</button> -->
                                                        </div>
                                                    </button>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isNewCampaign" class="card card-custom"  style="text-align: right; padding-bottom: 20px !important;" class="btn" style="border: 5px solid #EBE9EB;">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <h1 class="title">Add New Campaign</h1>
                                <div style="text-align: right;">
                                    <button type="button" class="btn btn-page" style="width: auto; margin-bottom: 20px !important;" (click)="isOptionSelected = false; isNewCampaign = false;">Change Selection</button>
                                </div>
                            </div>
                            <div>
                                <form [formGroup]="campForm">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 20px !important;">
                                            <label style="margin-right: 20px !important; text-align: start !important; min-width: 100px;">Client Record*</label>
                                            <ng-select [clearable]="false" (change)="changeCompanyFill($event);" [ngClass]="{'is-invalid': (attemptClick && ((fc.clientId.value === undefined) || (fc.clientId.value === ''))) }" bindLabel="fullName" bindValue="id" [items]="allMyClients" formControlName="clientId" style="flex-basis: 300px; text-align: start; min-width: 150px;"></ng-select>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; margin-bottom: 20px !important;">
                                            <label style="margin-left: 2% !important; margin-right: 2% !important; text-align: start !important; flex-basis: 100px;">Brand Name*</label>
                                            <input [ngClass]="{ 'is-invalid': attemptClick && brandPlaceholder === '' }" [(ngModel)]="brandPlaceholder" (change)="onNameChange($event)" type="text" formControlName="brandName" class="form-control" />
                                            <div *ngIf="attemptClick && fc.brandName.value === null" class="invalid-feedback" style="width: auto !important; margin-left: 20px !important; margin-right: 20px !important;">
                                                required
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
                                        <label style="text-align: start !important; margin-left: 120px;">Please don't include Brand Name in the Studio Location Name</label>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
                                        <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 20px !important; width: 100%;">
                                            <label style="margin-right: 20px !important; text-align: start !important; min-width: 99px; max-width: 101px;">Studio Location Name*</label>
                                            <input style="width: 100% !important; width: 100%;" (change)="onNameChange($event)" 
                                                [ngClass]="{ 'is-invalid': attemptClick && (fc.studioLocationName.value === null || fc.studioLocationName.value === '') }"
                                                type="text" formControlName="studioLocationName" class="form-control" />
                                            <div *ngIf="attemptClick && (fc.studioLocationName.value === null || fc.studioLocationName.value === '')"
                                                class="invalid-feedback" style="width: auto !important; margin-left: 20px !important; margin-right: 20px !important;">
                                                required
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" style="margin-bottom: 20px !important;" fxLayoutAlign="space-between center">
                                        <label style="margin-right: 20px !important; text-align: start !important; min-width: 100px;">Campaign Name*</label>
                                        <input readonly 
                                            [ngClass]="{ 'is-invalid': attemptClick && (fc.studioLocationName.value === null || fc.brandName.value === null) }"
                                            type="text" formControlName="campaignName" class="form-control" />
                                        <div *ngIf="attemptClick && (fc.studioLocationName.value === null || fc.brandName.value === null)"
                                            class="invalid-feedback" style="width: auto !important; margin-left: 20px !important; margin-right: 20px !important;">
                                            required
                                        </div>
                                    </div>
                                    <div fxLayout="row" style="margin-bottom: 20px !important;" fxLayoutAlign="space-between center">
                                        <label style="margin-right: 20px !important; text-align: start !important; min-width: 100px;">Studio Email Address*</label>
                                        <input 
                                            [ngClass]="{ 'is-invalid': attemptClick && fc.studioEmail.value === null }"
                                            type="text" formControlName="studioEmail" class="form-control" />
                                        <div *ngIf="attemptClick && fc.studioEmail.value === null"
                                            class="invalid-feedback" style="width: auto !important; margin-left: 20px !important; margin-right: 20px !important;">
                                            required
                                        </div>
                                        <label style="margin-right: 20px !important; margin-left: 20px !important; text-align: start !important; min-width: 100px;">Studio Phone Number*</label>
                                        <input
                                            [ngClass]="{ 'is-invalid': attemptClick && fc.studioPhone.value === null }"
                                            type="text" formControlName="studioPhone" class="form-control" />
                                        <div *ngIf="attemptClick && fc.studioPhone.value === null"
                                            class="invalid-feedback" style="width: auto !important; margin-left: 20px !important; margin-right: 20px !important;">
                                            required
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 20px !important;" class="col-md-12">
                                        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between center">
                                            <div fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">
                                                <label>Studio Address*</label>
                                                <input style="width: 100%; margin-right: 20px !important;" 
                                                [ngClass]="{ 'is-invalid': attemptClick && fc.studioAddress.value === null }"
                                                type="text" formControlName="studioAddress" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 20px !important;" fxLayout="row" fxLayoutAlign="space-between center">
                                        <label style="margin-right: 20px !important; text-align: start !important; min-width: 99px; max-width: 101px;">Facebook Page Url</label>
                                        <input 
                                            [ngClass]="{ 'is-invalid': fc.fbPageUrl.errors }"
                                            type="text" formControlName="fbPageUrl" class="form-control" />
                                    </div>
                                    <div style="margin-bottom: 20px !important;" fxLayout="row" fxLayoutAlign="space-between center">
                                        <label style="margin-right: 20px !important; text-align: start !important; min-width: 100px;">CRM Name</label>
                                        <input 
                                            [ngClass]="{ 'is-invalid': fc.crmName.errors }"
                                            type="text" formControlName="crmName" class="form-control" />
                                        <label style="margin-right: 20px !important; margin-left: 20px !important; text-align: start !important; min-width: 100px;">CRM ID</label>
                                        <input 
                                            [ngClass]="{ 'is-invalid': fc.crmId.errors }"
                                            type="text" formControlName="crmId" class="form-control" />
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <label style=" margin-bottom: 20px !important;">Notes</label>
                                    </div>
                                    <div class="col-md-12">
                                        <textarea type="text" rows="3" class="form-control" formControlName="notes"
                                            [ngClass]="{ 'is-invalid': fc.notes.errors }"
                                            placeholder="Enter note"> </textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div style="width: 100%; margin-top: 20px;" fxLayout="row" fxLayoutAlign="end center">
                            <button *ngIf="isNewCampaign" type="button" style="width: 25vw !important; margin-right: 0px !important;" (click)="attemptClick = true; onNext(CART_STEPS.RequiredInfo);"
                            [ngClass]="((fc.studioLocationName.value !== null) && (fc.clientId.value !== null) && (fc.clientId.value !== '') && (fc.brandName.value !== null) && (fc.studioLocationName.value !== '') && (fc.brandName.value !== '') && (fc.studioEmail.value !== null) && (fc.studioPhone.value !== null) && (fc.studioAddress.value !== null)) ? 'orange-button' : 'grey-button'">Next</button>
                        </div>
                    </div>
                </div>
            <div *ngIf="requiredInfoCompleted">
                <div fxLayout="column">
                    <p>No information is required for this product</p>
                    <p>Please go to next step</p>
                    <div style="height: 5px">
                        <hr>
                    </div>
                    <div style="width: 100%; margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-between center">
                        <button type="button" class="btn btn-page"
                            (click)="onPrevious(CART_STEPS.BundleDetails)">Back</button>
                        <button type="button" (click)="onNext(CART_STEPS.RequiredInfo)"
                            class="btn btn-page">Next</button>
                    </div>
                </div>
            </div>
            </aw-wizard-step>
            <aw-wizard-step style="margin-top: 40px" stepTitle="Ad Spend" (stepEnter)="onStepEnter($event,CART_STEPS.Cart)">
                <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
                    <h1>{{(addingFacebook || addingGoogle) ? 'Select Your Ad Spend' : 'Confirm Your Products'}}</h1>
                </div>
                <div formArrayName="stepsData" style="min-height: 600px !important; margin-bottom: 32px;" fxLayout="column" fxLayoutAlign="start center">
                    <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%;">
                        <div *ngFor="let bProduct of stepsData.controls; let i=index">
                            <div *ngIf="(bProduct.value.display.includes('Fee') && bProduct.value.display.includes('Management'))" [formGroupName]="i">
                                <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start start">
                                    <img [src]="bProduct.get('mediaUrl')?.value" style="height: auto; max-height: 50px !important; margin-right: 16px;">
                                    <div fxLayout="column" fxLayoutAlign="start start" style="width: 100% !important;">
                                        <h5 style="margin-top: 0px; margin-bottom: 0px !important;">Management Fee</h5>
                                        <p style="margin-bottom: 0px !important; max-width: 300px;">{{bProduct.get('description')?.value}}</p>
                                        <h4 *ngIf="bProduct.value.price != null && !showRecurring()" style="margin-bottom: 20px !important;">
                                            ${{bProduct.value.price}}
                                        </h4>
                                        <p *ngIf="bProduct.value.price != null && showRecurring()" style="margin-bottom: 20px !important;">
                                            ${{bProduct.value.price}} per month
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div *ngFor="let bProduct of stepsData.controls; let i=index" fxLayout="row" fxLayoutAlign="center">
                            <div *ngIf="!(bProduct.value.display.includes('Fee') && bProduct.value.display.includes('Management'))" [formGroupName]="i">
                                <div [ngClass]="(bProduct?.get('includeProduct')?.value) ? 'block' : 'grey-block'" style="min-width: 300px; margin-right: 40px !important; margin-left: 40px !important;" fxLayout="column">
                                    <div style="width: 100%" fxLayout="row"
                                    fxLayoutAlign="center start">
                                        <h4>{{bProduct.get('display')?.value}}</h4>
                                    </div>
                                    <div style="margin-top: 20px; margin-bottom: 20px;"
                                        fxLayout="column" fxLayoutAlign="start center">
                                        <img [src]="bProduct.get('mediaUrl')?.value"
                                            style="height: auto; max-height: 150px !important;">
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100% !important; min-height: 100px !important;">
                                        <p style="width: 60%;">{{bProduct.get('description')?.value}}</p>
                                    </div>
                                    <br>
                                    <div *ngIf="(stepsData.controls.length > 1) && bProduct.get('isRequired') !==null && bProduct.get('isRequired')?.value === false"
                                        fxLayout="row" fxLayoutAlign="center center">
                                        <label>Include this product?</label>
                                        <div style="padding-left: 0px">
                                            <ui-switch (valueChange)="onSwitchChange($event,i)"
                                                size="small" defaultBoColor="#dfdfdf"
                                                color="#4ba6ef"
                                                [checked]="bProduct?.get('includeProduct')?.value"
                                                checkedLabel="Yes" uncheckedLabel="No" class="ms-1">
                                            </ui-switch>
                                        </div>
                                    </div>
                                    <br>
                                    <div>
                                        <h4 *ngIf="bProduct.value.price != null && !showRecurring()" style="margin-bottom: 20px !important;">
                                            ${{bProduct.value.price}}
                                        </h4>
                                        <h4 *ngIf="bProduct.value.price != null && showRecurring()" style="margin-bottom: 20px !important;">
                                            ${{bProduct.value.price}} per month
                                        </h4>
                                    </div>
                                    <div *ngIf="(bProduct.get('type')?.value === PRODUCT_TYPES.VariableSubscription.code) && (!hasGoogle && !hasFacebook)"
                                        fxLayout="column" fxLayoutAlign="center center" style=" padding-top: 20px; border-top: 1px solid darkgrey;">
                                        <label>Monthly Ad Spend</label>
                                        <div>
                                            <select formControlName="adSpend"
                                                (change)="doFinalizeProducts()" class="form-control"
                                                style="width: 120px">
                                                <option [value]="o"
                                                    *ngFor="let o of productAdSpendOptions.get(bProduct.get('id')?.value)">
                                                    {{o | currency:'USD' : 'symbol' :
                                                    '1.0-0'}}
                                                </option>
                                            </select>
                                        </div>
                                        <p style="margin-top: 20px;">*Management fee will be calculated in cart</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <button style="width: 300px" type="button" class="orange-button" (click)="onPrevious(CART_STEPS.RequiredInfo);">Back</button>
                    <button style="width: 300px" type="button" (click)="continueToCart();"
                    [class]="(((!hasFacebook) && (!hasGoogle)) || (!addingFacebook && !addingGoogle)) ? 'orange-button' : 'grey-button'">Continue To Cart</button>
                </div>
            </aw-wizard-step>
            <aw-wizard-step style="margin-top: 40px" stepTitle="Checkout">
                <div *ngIf="activeCart.currentStep===CART_STEPS.Cart" fxLayout="column">
                    <div fxLayout="row" style="width: 55vw;" fxLayoutAlign="space-between center">
                        <button style="width: 100px; margin-left: 0px !important;" type="button" class="orange-button" (click)="onPrevious(CART_STEPS.Cart);">Back</button>
                        <h1 class="title">Checkout</h1>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <div fxLayout="column" fxLayoutAlign="space-between start">
                            <div style="width: 55vw !important; padding-top: 20px !important; padding-bottom: 20px !important;" fxLayout="row" fxLayoutAlign="space-between start">
                                <p style="font-size: 18px; font-weight:bold;">1. Location</p>
                                <div fxLayout="column" fxLayoutAlign="start end">
                                    <p style="color: black; font-size: 18px;">{{locationTitle}}</p>
                                    <button style="color: orange;" (click)="onPrevious(2); onPrevious(1);">Change</button>
                                </div>
                            </div>
                            <div style="border-top: 2px solid black; width: 55vw !important; padding-top: 20px !important;" fxLayout="row" fxLayoutAlign="space-between start">
                                <p style="font-size: 18px; font-weight:bold;">2. Start Date</p>
                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <button *ngIf="selectedStartTime === 2 || selectingDate || selectedStartTime === 0" style="color: black; font-size: 18px; margin-bottom:20px;" (click)="clickDate(2);"><input *ngIf="selectingDate || selectedStartTime === 0" style="margin-right: 20px;" type="checkbox">1st Of Next Month</button>
                                    <button *ngIf="selectedStartTime === 1 || selectingDate || selectedStartTime === 0" style="color: black; font-size: 18px;" (click)="clickDate(1);"><input *ngIf="selectingDate || selectedStartTime === 0" style="margin-right: 20px;" type="checkbox">As Soon As Possible</button>
                                    <strong *ngIf="(selectedStartTime === 1) && (((getStartDate() | P_AppDateFormat) !== '') && ((getEndDate() | P_AppDateFormat) !== '')) && showRecurring()" style="font-size: 14px; margin-bottom:20px; width: 100%; text-align: end;">Date Range: {{(getStartDate() | P_AppDateFormat)}} - {{(getEndDate() | P_AppDateFormat)}}</strong>
                                    <strong *ngIf="selectedStartTime === 0 || selectingDate" style="font-size: 14px; margin-bottom:20px;"></strong>
                                    <div fxLayout="row" style="width: 100%;" fxLayoutAlign="end center">
                                        <button *ngIf="!selectingDate && (selectedStartTime !== 0) && showRecurring()" style="color: orange; margin-bottom:20px;" (click)="this.selectingDate = !this.selectingDate;">Change</button>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 55vw !important;" fxLayout="row" fxLayoutAlign="space-between start">
                                <div></div>
                                <div *ngIf="paymentSubmitted && selectedStartTime === 0" style="color: red; margin-bottom: 20px;">Please select a start date</div>
                            </div>
                            <div style="border-top: 2px solid black; width: 55vw !important; padding-top: 20px !important; padding-bottom: 20px !important;" fxLayout="row" fxLayoutAlign="space-between start">
                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <p style="font-size: 18px; font-weight:bold;">3. Payment Method</p>
                                    <p *ngIf="holdClients.length > 1" style="font-size: 14px; font-weight:normal;">Select A Client Record:</p>
                                    <form *ngIf="holdClients.length > 1" [formGroup]="paymentForm" fxLayout="column" fxLayoutAlign="center end">
                                        <ng-select [clearable]="false"
                                        [ngClass]="{'is-invalid': paymentSubmitted && fp.clientId.errors, 'disabled-dropdown': holdClients.length === 1 }"
                                        style="width: 300px !important;" (change)="onPaymentClientChange($event)"
                                        bindLabel="fullName" bindValue="id" [items]="holdClients" formControlName="clientId" >
                                        </ng-select>
                                    </form>
                                </div>
                                <div fxLayout="column" style="width: 75%;" fxLayoutAlign="center end">
                                    <div [ngStyle]="{'color': (paymentSubmitted && fp.methodId.errors?.required) ? 'red' : 'black'}" style="margin-bottom: 20px;">
                                        {{(!(myPaymentMethods.length > 0)) ? 'Please add a payment method' : 'Please select a payment method'}}
                                    </div>
                                    <!--(change)="onPaymentClientChange($event)"-->
                                    <form [formGroup]="paymentForm" fxLayout="column" fxLayoutAlign="center end">
                                        <div class="scrolling-container" fxLayout="row" fxLayoutAlign="end start">
                                            <ng-container *ngFor="let p of myPaymentMethods">
                                                <button *ngIf="p.expiry != null" fxLayout="column" style="margin-right: 5px !important; margin-left: 5px !important; min-height: 75px !important; min-width: 200px; margin-bottom: 10px; margin-top: 10px;" (click)="choosePayment(myPaymentMethods.indexOf(p)); onPaymentMethodChange(p.id);" [ngClass]="chosenPayment === myPaymentMethods.indexOf(p) ? 'btn credit-card-box chosen-block' : 'btn credit-card-box block'" fxLayoutAlign="space-between start">
                                                    <div fxLayout="column" fxLayoutAlign="start start">
                                                        <div style="font-weight: bold; font-size: 16px;">{{p.nickname}}</div>
                                                        <div style="margin-bottom: 10px;">Card Ending In {{p.cardNumber}}</div>
                                                        <p>Expires {{p.expiry}}</p>
                                                    </div>
                                                    <div style="width: 100% !important;" fxLayout="row" fxLayoutAlign="end end">
                                                        <button (click)="openEdit(p.id);" style="color: blue;">Edit</button>
                                                    </div>
                                                </button>
                                            </ng-container>
                                        </div>
                                        <div fxLayout="column" fxLayoutAlign="center center" style="text-align: center;margin-top: 30px;">
                                            <button type="button" class="btn" style="display: inline-flex; /* Center content */
                                                align-items: center; /* Center content vertically */
                                                justify-content: center; /* Center content horizontally */
                                                width: 50px; /* Button width */
                                                height: 50px; /* Button height */
                                                border-radius: 50%; /* Make it round */
                                                border: 2px solid grey; /* Border color */
                                                color: grey; /* Text/Icon color */
                                                background-color: transparent; /* Transparent background */
                                                font-size: 24px; /* Icon size */
                                                cursor: pointer; /* Change cursor on hover */" (click)="choosePayment(chosenPayment); onAddNew(); onPaymentMethodChange(false);">
                                                +
                                            </button>
                                            <div style="text-align: center;font-weight: bold;font-size: large;">
                                                Add New
                                            </div>
                                        </div>
                                    </form>
                                    <div style="margin-top: 40px !important;" fxLayout="column" fxLayoutAlign="start end">
                                        <label style="min-width: 100px; margin-bottom: 10px; font-size: 18px; font-weight:bold;"><u>Add Coupon Code</u></label>
                                        <mat-form-field style="max-width: 250px !important;" class="example-chip-list" appearance="fill">
                                            <mat-chip-list #chipList aria-label="Fruit selection">
                                                <mat-chip *ngFor="let fruit of coupons" [selectable]="true"
                                                        [removable]="true" (removed)="remove(fruit)">
                                                    {{fruit.name}}
                                                    <button *ngIf="fruit.name.toLowerCase() !== 'first'" matChipRemove>
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                </mat-chip>
                                                <input placeholder="Coupon..."
                                                        [matChipInputFor]="chipList"
                                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                        [matChipInputAddOnBlur]="true"
                                                        (matChipInputTokenEnd)="add($event)">
                                            </mat-chip-list>
                                        </mat-form-field>
                                        <button (click)="onApplyCoupon();" class="btn btn-black">Apply Coupon</button>
                                        <div *ngIf="couponResponse.couponApplied && (this.uniqueCouponCodes.length === this.coupons.length)" style="margin-top: 20px; color: blue" role="alert">
                                            Coupon Applied
                                        </div>
                                        <label style="color: red; text-align: end; margin-top: 20px !important;" *ngIf="(this.cartStepData.couponDetails != null) && (this.uniqueCouponCodes.length != this.coupons.length)">Please apply your coupons before checkout</label>
                                    </div>
                                </div>
                            </div>
                            <div style="border-top: 2px solid black; width: 55vw !important; padding-top: 20px !important;" fxLayout="column" fxLayoutAlign="end end">
                                <div fxLayout="column" style="width: 375px;" fxLayoutAlign="start end">
                                    <div *ngIf="cartStepData!.productDiscount ?? 0 > 0">
                                        <strong style="font-size: 18px; font-weight: bold;">Product coupon amount</strong><br/>
                                        <p style="font-size: 16px; margin-bottom: 0px !important; text-align: end;">{{cartStepData?.productDiscount | currency}} ({{cartStepData?.productCoupon}})</p>
                                    </div>
                                    <div *ngIf="(cartStepData!.cartDiscount ?? 0 > 0)">
                                        <strong style="font-size: 18px; font-weight: bold;">Cart coupon amount</strong><br/>
                                        <p style="font-size: 16px; margin-bottom: 0px !important; text-align: end;">{{cartStepData?.cartDiscount | currency}} ({{cartStepData?.cartCoupon}})</p>
                                    </div>
                                    <div *ngIf="((cartStepData!.cartDiscount ?? 0 > 0) || (cartStepData!.productDiscount ?? 0 > 0))" style="margin-bottom: 10px;">
                                        <strong style="font-size: 18px; font-weight: bold;">Coupon Total</strong>
                                        <br/>
                                        <p style="font-size: 16px; margin-bottom: 0px !important; text-align: end;">{{((cartStepData?.cartDiscount!) + (cartStepData?.productDiscount!)) | currency}}</p>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="end center">
                                        <strong style="font-size: 20px; font-weight: bold;">Order Total: {{(selectedStartTime === 0) ? (cartStepData?.recurringTotal | currency) : (cartStepData?.total | currency)}}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>          
                        <div class="checkout-section" fxLayout="column" fxLayoutAlign="start end" style="margin-left: 10px;">
                            <div class="checkout-box" #stickyBox>
                                <div fxLayout="column" fxLayoutAlign="start center">
                                    <div style="padding-top: 10px; padding-left: 10px; padding-right: 10px; margin-bottom: 10px; width: 100%;">
                                        <strong style="margin-bottom: 20px !important;">Items:</strong>
                                        <div *ngFor="let bProduct of finalizedProducts" style="width: 100%;" fxLayout="row" fxLayoutAlign="space-between center">
                                            <div fxLayout="row" fxLayoutAlign="start center">
                                                <p style="max-width: 80% !important;">{{bProduct.display}}</p>
                                            </div>
                                            <div fxLayout="column" fxLayoutAlign="start end">
                                                <div *ngIf="bProduct.type === PRODUCT_TYPES.SimpleProduct.code">
                                                    <p>
                                                        <span>{{(bProduct?.data?.price!) | currency}}</span>
                                                    </p>
                                                </div>
                                                <div *ngIf="bProduct.type === PRODUCT_TYPES.SimpleSubscription.code">
                                                    <p [ngClass]="{'strike':
                                                    ((selectedStartTime === 1) || (selectedStartTime === 2))}">
                                                        {{(bProduct?.data?.price!) | currency}}
                                                    </p>
                                                    <p style="text-align: end;" *ngIf="(selectedStartTime !== 0)">
                                                        {{(selectedStartTime === 2) ? ('$0') : (bProduct?.proratePrice) | currency}}
                                                    </p>
                                                </div>
                                                <div *ngIf="bProduct.type === PRODUCT_TYPES.VariableSubscription.code">
                                                    <p [ngClass]="{'strike': ((selectedStartTime === 2) || (selectedStartTime === 1))}">
                                                        {{bProduct?.adSpend | currency}}
                                                    </p>
                                                    <p style="text-align: end;" *ngIf="(selectedStartTime !== 0)">
                                                        {{(selectedStartTime === 2) ? ('$0') : (bProduct?.proratePrice) | currency}}
                                                    </p>
                                                </div>
                                                <div *ngIf="bProduct?.data?.signupFee">
                                                    <p>
                                                        {{bProduct?.data?.signupFee | currency}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="((cartStepData!.cartDiscount ?? 0 > 0) || (cartStepData!.productDiscount ?? 0 > 0))" style="margin-bottom: 10px;">
                                            <strong>Coupon Total</strong>
                                            <br/>
                                            {{((cartStepData?.cartDiscount!) + (cartStepData?.productDiscount!)) | currency}}
                                        </div>
                                        <strong style="font-size: 20px; font-weight:bold;">Total: {{(selectedStartTime === 0) ? (cartStepData?.recurringTotal | currency) : (cartStepData?.total | currency)}}</strong>
                                    </div>
                                    <form fxLayout="row" style="width: calc(100% - 20px) !important;" fxLayoutAlign="start center" [formGroup]="paymentForm">
                                        <label style="width: 100% !important; text-align: start;" for="agreeTerms">I have read and agreed to the <a href="https://franchisefocusedmarketing.com/terms/" target="_blank">terms of service</a></label>
                                        <input type="checkbox" id="agreeTerms" formControlName="agreeTerms"
                                            style="margin-left: 2px !important; margin-right: 10px !important;"/>
                                    </form>
                                    <label style="color: red; text-align: start;" *ngIf="paymentSubmitted && fp.agreeTerms.errors?.required">Please accept the agreement</label>
                                    <button style="margin-bottom: 5px; width: calc(100% - 20px); text-align: center;" (click)="this.placeOrder();" [ngClass]="((selectedStartTime !== 0) && !fp.methodId.errors?.required && !fp.agreeTerms.errors?.required && (this.uniqueCouponCodes.length == this.coupons.length)) ? 'orange-button' : 'grey-button'">Place Order</button>
                                    <div *ngIf="(cartStepData!.productDiscount ?? 0 > 0) || (cartStepData!.cartDiscount ?? 0 > 0) || showRecurring()" style="background-color: #EBE9EB; padding: 10px; border-radius: 0 0 15px 15px; width: 100% !important">
                                        <div *ngIf="showRecurring()">
                                            <strong>Recurring Total: {{cartStepData?.recurringTotal | currency}}</strong><strong *ngIf="(cartStepData!.productDiscount ?? 0 > 0) || (cartStepData!.cartDiscount ?? 0 > 0)">*</strong>
                                            <br>
                                            First Renewal: {{getRecurringDate() | P_AppDateFormat}}
                                        </div>
                                        <div *ngIf="(cartStepData!.productDiscount ?? 0 > 0) || (cartStepData!.cartDiscount ?? 0 > 0)">
                                            <p style="margin-bottom: 0px !important;">*Coupon discounts may or may not persist for subscriptions. Consult with your Business Development representative to confirm this.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>
        </form>
    </aw-wizard>
</div>
