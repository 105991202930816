<div class="row">
  <div class="col-md-12">
    <div class="card card-custom">
      <div class="card-body">
        <div class="details_top">
          <h2 style="text-align: center;">{{clientData?.firstName}} {{clientData?.lastName}}</h2>
          <h4 style="text-align: center;">{{clientData?.brand}}</h4>
          <div class="row row-gap-4 d-flex align-items-stretch" style="margin-top: 25px;">
            <div class="contact_info col-12 " [ngClass]="(isExternal) ? 'col-xl-6' : 'col-xl-6'">
              <div class="card client-card">
                <h5 class="card-title">Contact Info:</h5>
                <div class="card-body">
                  <p><strong>Email:</strong> {{clientData?.email}} <a *ngIf="clientData.email" style="color: inherit;" href="mailto:{{ clientData.email }}"><i-feather name="mail" style="width: 1.3em; height: 1em; stroke-width: 1px;"></i-feather></a></p>
                  <p><strong>Phone:</strong> {{clientData?.phone}}</p>
                  <p><strong>Address:</strong><br *ngIf="clientData?.brandAddress" />
                    {{clientData?.brandAddress}}</p>
                </div>
              </div>
            </div>
            <div class="additional_info col-12 " [ngClass]="(isExternal) ? 'col-xl-6' : 'col-xl-6'">
              <div class="card client-card">
                <h5 class="card-title">Additional Info:</h5>
                <div class="card-body">
                  <p><strong>DMS:</strong> {{ clientData?.dmsName }}</p>
                  <p><strong>Sales Rep:</strong> {{ clientData?.salesRep }}</p>
                  <p *ngIf="clientData?.emailForInvoice"><strong>Invoice Email:</strong> {{ clientData?.emailForInvoice }}</p>
                  <p *ngIf="!isExternal"><strong>Owner User Record:</strong> {{clientData?.clientOwner}}</p>
                  <p><strong>Employee Users:</strong><br *ngIf="clientData?.employees?.length !== 0" />
                    <span *ngFor="let e of clientData.employees; let last = last">
                    {{ e.firstName }} {{ e.lastName }}<ng-container *ngIf="!last">, </ng-container>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dv-custom-navs">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
              <li [ngbNavItem]="1" [ngStyle]="{'z-index': (active === 1) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.notes && roleOptions.notes.canRead)">
                <a ngbNavLink>
                  <i-feather name="paperclip"></i-feather><label>NOTES</label>
                </a>
                <ng-template ngbNavContent>
                  <app-notes [iIsAdmin]="isAdmin" [iIsExternal]="isExternal"
                    [iCanDelete]="isAdmin || roleOptions.notes.canDelete"
                    [iCanWrite]="isAdmin || roleOptions.notes.canWrite" [iParentId]="clientData.id" [iType]="2">
                  </app-notes>
                </ng-template>
              </li>
              <li [ngbNavItem]="2" style="left: -5px;" [ngStyle]="{'z-index': (active === 2) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canRead)">
                <a ngbNavLink>
                  <i-feather name="edit"></i-feather><label>CAMPAIGNS</label>
                </a>
                <ng-template ngbNavContent>
                  <div style="background-color: white; padding: 8px 16px 8px 16px;">
                    <div style="display: flex; background-color: white; padding-bottom: 16px;">
                      <div style="flex: 1;"></div>
                      <button *ngIf="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canWrite)" class="blue-button"
                        type="button" (click)="onAddCampaign()">ADD CAMPAIGN</button>
                    </div>
                    <app-campaign-datatable [iCanRead]="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canRead)"
                      [iCanWrite]="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canWrite)"
                      [iCanDelete]="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canDelete)"
                      (oRowClick)="onCampaignsRowClick($event)" [iColumns]="columnsCampaign" [iShowActions]="false"
                      [iData$]="campaignsData" [iRowLinkHandler]="getCampaignRowLink" [iShowSelect]="false">
                    </app-campaign-datatable>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="3" style="left: -10px;" [ngStyle]="{'z-index': (active === 3) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)">
                <a ngbNavLink>
                  <i-feather name="list"></i-feather><label>TASKS</label>
                </a>
                <ng-template ngbNavContent>
                  <div style="background-color: white !important;">
                    <div style="display: flex; padding: 8px 16px 16px 16px; align-items: center; justify-content: end;">
                      <button class="blue-button" type="button" (click)="onAddTask()">ADD TASK</button>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div (click)="changeShowTasks()" style="padding-left: 16px; padding-right: 16px;">
                          <h2 [style.color]="showTasks ? '#495057' : '#EBE9EB'" [style.border-bottom]="showTasks ? '2px solid rgb(73, 80, 87)' : '2px solid #EBE9EB'" style="margin-bottom: 0px !important; padding: 0px 16px 8px 16px; cursor: pointer;">TO-DO</h2>
                        </div>
                        <div (click)="changeShowCompletedTasks()" style="padding-left: 16px; padding-right: 16px;">
                          <h2 [style.color]="showCompletedTasks ? '#495057' : '#EBE9EB'" [style.border-bottom]="showCompletedTasks ? '2px solid rgb(73, 80, 87)' : '2px solid #EBE9EB'" style="padding: 0px 16px 8px 16px; margin-bottom: 0px !important; cursor: pointer;">COMPLETED</h2>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" style="padding-right: 16px;">
                        <input type="checkbox" id="showMyTasksCheckbox" [(ngModel)]="showOnlyMyTasks" (change)="onShowMyTasksChange()"
                        value="Show Only My Tasks"><label style="margin-left: 8px !important; margin-bottom: 0px !important;" for="showMyTasksCheckbox">Show Only My Tasks</label>
                      </div>
                    </div>
                    <app-tasks-datatable *ngIf="showTasks" [iCanRead]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)"
                      [iCanWrite]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canWrite)"
                      [iCanDelete]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canDelete)"
                      (oRowClick)="onTaskRowClick($event)" [iSortColumn]="'title'" [iColumns]="columnsTasks"
                      [iShowActions]="false" [iData$]="tasksData">
                    </app-tasks-datatable>
                    <app-tasks-datatable *ngIf="showCompletedTasks" [iCanRead]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)"
                      [iCanWrite]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canWrite)"
                      [iCanDelete]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canDelete)"
                      (oRowClick)="onTaskRowClick($event)" [iSortColumn]="'completedOn'" [iSortDirection]="'desc'"
                      [iColumns]="completedTasksColumns" [iShowActions]="false" [iData$]="completedTasksData" [isCompleted]="true">
                    </app-tasks-datatable>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="4" style="left: -15px;" [ngStyle]="{'z-index': (active === 4) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.tags && roleOptions.tags.canRead)">
                <a ngbNavLink>
                  <i-feather name="tag"></i-feather><label>TAGS</label>
                </a>
                <ng-template ngbNavContent>
                  <app-tags-tab [iCanWrite]="isAdmin || (roleOptions.tags && roleOptions.tags.canWrite)"
                    [iParentId]="clientData.id" [iType]="2"></app-tags-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="5" style="left: -20px;" [ngStyle]="{'z-index': (active === 5) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.subscriptions && roleOptions.subscriptions.canRead)">
                <a ngbNavLink>
                  <i-feather name="refresh-cw"></i-feather><label>SUBSCRIPTIONS</label>
                </a>
                <ng-template ngbNavContent>
                  <app-subscription-shared [iClientId]="clientData.id">
                  </app-subscription-shared>
                </ng-template>
              </li>
              <li style="left: -25px;" (click)="loadOrders();" [ngStyle]="{'z-index': (active === 6) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.subscriptions && roleOptions.subscriptions.canRead)" [ngbNavItem]="6">
                <a ngbNavLink>
                  <i-feather name="dollar-sign"></i-feather><label>ORDERS</label>
                </a>
                <ng-template ngbNavContent>
                  <div style="background-color: white; padding: 24px 16px 8px 16px;">
                    <app-campaign-datatable
                      iSortColumn="numericId"
                      iSortDirection="desc"
                      [iCanRead]="doWhat.canRead"
                      [iColumns]="orderColumns"
                      [iData$]="checkingOrders"
                      [iHideHeaderActions]="false"
                      [iShowActions]="true"
                      [iShowEditAction]="false"
                      [iShowExport]="false"
                      [iShowSelect]="false"
                      [iShowViewAction]="true"
                      [iTotalCount]="checkingOrders.length"
                      (oRowClick)="onClick($event)">
                    </app-campaign-datatable>
                  </div>
                </ng-template>
              </li>
              <li style="left: -30px;" [ngStyle]="{'z-index': (active === 7) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.reports && roleOptions.reports.canRead)" [ngbNavItem]="7">
                <a ngbNavLink>
                  <i-feather name="file-text"></i-feather><label>REPORTING</label>
                </a>
                <ng-template ngbNavContent>
                  <div style="background-color: white; padding: 8px 16px 8px 16px;">
                    <div style="display: flex; margin-bottom: 16px; justify-content: flex-end;">
                      <button *ngIf="isAdmin || (roleOptions.reports && roleOptions.reports.canWrite)" class="blue-button"
                        type="button" (click)="addNewReportingConfig()">ADD REPORTING</button>
                    </div>
                    <app-special-datatable [iCanRead]="isAdmin || (roleOptions.reports && roleOptions.reports.canRead)"
                      [iCanWrite]="isAdmin || (roleOptions.reports && roleOptions.reports.canEdit)"
                      [iCanDelete]="isAdmin || (roleOptions.reports && roleOptions.reports.canDelete)"
                      [iColumns]="reportingConfigTableColumns" [iData$]="reportingConfigTableData" [iShowActions]="true"
                      [iShowExport]="false" (oAddNew)="addNewReportingConfig()" (oEditRow)="editReportingConfigRow($event)"
                      (oDeleteRows)="deleteReportingConfigRows($event)">
                    </app-special-datatable>
                  </div>
                </ng-template>
              </li>
              <li style="left: -35px;" (click)="filterLeads();" [ngStyle]="{'z-index': (active === 8) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.dashboard && roleOptions.dashboard.canRead)" [ngbNavItem]="8">
                <a ngbNavLink>
                  <i-feather name="bar-chart-2"></i-feather><label>LEADS</label>
                </a>
                <ng-template ngbNavContent>
                  <div style="background-color: white; padding: 24px 16px 8px 16px;">
                    <app-campaign-datatable [iCanRead]="doWhat.canRead" [iCanWrite]="doWhat.canWrite" [iCanDelete]="doWhat.canDelete"
                        [iColumns]="campaignColumns" (oDeleteRows)="onDelete($event)" [iRowLinkHandler]="getRowLink"
                        [iTotalCount]="leadsTotalCount"
                        [iData$]="leadsData" (oLoadDataFromServer)="onLoadingData($event)" [iShowSelect]="false"
                        iSortColumn="createdat" iSortDirection="desc" [iShowSelect]="true">
                    </app-campaign-datatable>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</div>