<div class="modal-box" cdkDrag>
    <form [formGroup]="addClientForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">{{ id === null ? 'Add' :'Edit'}} {{isProspect ? 'Prospect' : 'Client'}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label class="col-sm-3 center-label">First Name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="firstName"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="Enter first name">
                </div>
            </div>
            <div *ngIf="submitted && f.firstName.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.firstName.errors.required">
                        First Name is required
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="lastName">Last Name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="lastName"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" placeholder="Enter last name">
                </div>
            </div>
            <div *ngIf="submitted && f.lastName.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.lastName.errors.required">
                        Last Name is required
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="brandAddress">Address</label>
                <div class="col-sm-9">
                    <textarea type="text" class="form-control" rows="3" formControlName="brandAddress"
                        [ngClass]="{ 'is-invalid': submitted && f.brandAddress.errors }"
                        placeholder="Enter address info"> </textarea>
                    <div *ngIf="submitted && f.brandAddress.errors" class="invalid-feedback">
                        <div *ngIf="f.brandAddress.errors.required">address is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="brandId">Company</label>
                <div class="col-sm-9">
                    <ng-select placeholder="Select a Company" [ngClass]="{ 'is-invalid': submitted && f.brandId.errors }"
                        formControlName="brandId">
                        <ng-option *ngFor="let rep of allCompanies" [value]="rep.id">{{rep.name}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="submitted && f.brandId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.brandId.errors.required">
                        Company is required
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="dmsId">DMS</label>
                <div class="col-sm-9">
                    <ng-select placeholder="Select a DMS" [ngClass]="{ 'is-invalid': submitted && f.dmsId.errors }"
                        formControlName="dmsId">
                        <ng-option *ngFor="let rep of allDms" [value]="rep.id">{{rep.firstName}} {{rep.lastName}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="submitted && f.dmsId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.dmsId.errors.required">
                        DMS is required
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="salesRepId">Sales Rep</label>
                <div class="col-sm-9">
                    <ng-select placeholder="Select a Sales Rep"
                        [ngClass]="{ 'is-invalid': submitted && f.salesRepId.errors }" formControlName="salesRepId">
                        <ng-option *ngFor="let rep of allSalesReps" [value]="rep.id">{{rep.firstName}} {{rep.lastName}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="submitted && f.salesRepId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.salesRepId.errors.required">
                        Sales Rep is required
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="clientOwnerId">Owner</label>
                <div class="col-sm-9">
                    <ng-select placeholder="Select a Client Owner"
                        [ngClass]="{ 'is-invalid': submitted && f.clientOwnerId.errors }"
                        [readonly]="client != undefined && !client.clientOwnerEditable" formControlName="clientOwnerId">
                        <ng-option *ngFor="let user of allClientOwners" [value]="user.id">{{user.firstName}}
                            {{user.lastName}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="submitted && f.clientOwnerId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.clientOwnerId.errors.required">
                        Owner is required
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3">Employees</label>
                <div class="col-sm-9">
                    <ul class="list-group">
                        <li class="list-group-item" *ngIf="f.employees.value?.length === 0">No employee found</li>
                        <li class="list-group-item" *ngFor="let e of f.employees.value">{{e.fullName }}</li>
                    </ul>
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="phone">Phone #</label>
                <div class="col-sm-9">
                    <input type="tel" class="form-control" formControlName="phone"
                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Enter phone number">
                </div>
            </div>
            <div *ngIf="submitted && f.phone.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.phone.errors.required">
                        Phone Number is required
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="email">Email</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Enter email address">
                </div>
            </div>
            <div *ngIf="submitted && f.email.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.email.errors.required">
                        Email address is required
                    </div>
                    <div *ngIf="f.email.errors.email">Email address is invalid</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="emailForInvoice">If the client wants invoices to go to a different email than above</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="emailForInvoice"
                         placeholder="Enter invoice email address">
                </div>
            </div>
            <div class="row mb-3">
                <!-- <label class="col-sm-3" for="name">Is Prospect</label>
                <div class="col-sm-9">
                    <input type="checkbox" formControlName="isProspect" class="form-check-input" />
                </div> -->
                <label class="col-sm-3 center-label">Prospect/Client</label>
                <div style="padding-left: 0px" class="col-sm-9">
                    <ui-switch [checked]="f.isProspect.value" size="small" (change)="f.isProspect.setValue($event)"
                        defaultBoColor="#dfdfdf" color="#4ba6ef" checkedLabel="Prospect" uncheckedLabel="Client"
                        class="ms-1">
                    </ui-switch>
                </div>
            </div>
            <!-- <div class="row mb-3">
                <label class="col-sm-3 center-label">Send Invoice Email?</label>
                <div style="padding-left: 0px" class="col-sm-9">
                    <ui-switch [checked]="f.isSendInvoice.value" size="small" (change)="f.isSendInvoice.setValue($event)"
                        defaultBoColor="#dfdfdf" color="#4ba6ef" checkedLabel="Yes" uncheckedLabel="No"
                        class="ms-1">
                    </ui-switch>
                </div>
            </div> -->
            
            <!-- <div class="row mb-3">
                <label class="col-sm-3" for="marketingManagers">Employees</label>
                <div class="col-sm-9">
                    <ng-select placeholder="Select" [multiple]="true" [items]="allMarketingManagers" bindLabel="fullName" bindValue="id"
                        formControlName="marketingManagers">
                    </ng-select>
                </div>
            </div> -->

        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="blue-button">SAVE</button>
        </div>
    </form>
</div>