import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TASK_PRIORITY } from '../../../core/helpers/constants';
import { createGuid } from '../../../core/helpers/utility';
import { IClient } from '../../../core/models/clients.models';
import { DataFeedService } from '../../api/datafeed.service';
import * as util from '../../../core/helpers/utility'
import { ICampaign } from '../../../core/models/campaigns.model';
import { IUser } from '../../../core/models/auth.models';
import { ITask } from '../../../core/models/tasks.models';
import * as moment from 'moment'
import { TasksApiService } from '../../../pages/tasks/tasks.apiservice';
import { CampaignsApiService } from '../../../pages/campaigns/campaigns.apiservice';
import * as _ from 'underscore'
import { Utility } from '../../utility';
import Swal from 'sweetalert2';

@Component({
  selector: 'modal-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskModal implements OnInit, OnDestroy {

  calendarRoot: string = 'https://calendar.google.com/calendar/render?action=TEMPLATE&text=';
  calendarDetailsLabel: string = '&details=';
  calendarDatesLabel: string = '&dates=';

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();
  @Output() oDeleteClicked = new EventEmitter<any>();
  @Output() oMarkAsCompleteClicked = new EventEmitter<any>();

  public TASK_PRIORITY = TASK_PRIORITY;

  allClients: Array<IClient> = new Array<IClient>();
  allCampaigns: Array<ICampaign> = new Array<ICampaign>();
  fiteredCampaigns: Array<ICampaign> = new Array<ICampaign>();
  allUsers: Array<IUser> = new Array<IUser>();

  addTaskForm!: FormGroup;
  submitted = false;
  @Input() iCanEdit = true;
  @Input() iCanDelete = true;
  @Input() set iClientId(value: any) {
    if (value !== null)
      this.addTaskForm.controls['clientId'].patchValue(value);
  }
  @Input() set iCampaignId(value: any) {
    if (value !== null)
      this.addTaskForm.controls['campaignId'].patchValue(value);
  }
  @Input() set iId(value: any) {
    this.id = value;
    if (value !== null) {
      this.taskService.getTaskDetails(value)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          response.data.clientId = response.data.clientId ?? -1;
          response.data.campaignId = response.data.campaignId ?? -1;
          this.addTaskForm.patchValue(response.data);
          this.addTaskForm.patchValue({ dueDate: new Date(response.data.dueDate) })
          this.iCanEdit = this.iCanEdit;
          this.iCanDelete = response.data?.canTaskDelete;
        });
    }
  }
  id: number = -1;

  constructor(
    private dataFeedService: DataFeedService,
    private taskService: TasksApiService,
    public activeModal: NgbActiveModal,
    private campaignService: CampaignsApiService,
    private formBuilder: FormBuilder) {
    this.addTaskForm = this.formBuilder.group({
      id: [null],
      test: [null],
      title: ['', [Validators.required]],
      priority: [2, [Validators.required]],
      campaignId: [null],
      clientId: [null],
      dueDate: [null, Validators.required],
      description: ['', [Validators.required]],
      assignedUserId: ['', [Validators.required]],
      isCompleted: [null]
    });

    this.dataFeedService.getAllClients()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allClients = _.sortBy([...response.data], 'firstName').map(i => { i.fullName = i.firstName + ' ' + i.lastName; return i; });
          this.addTaskForm.controls['clientId'].patchValue(this.addTaskForm.controls['clientId'].value);
        }
      });
    this.campaignService.getAllCampaigns({})
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allCampaigns = [...response.data];
        }
      });

    this.dataFeedService.getAllTaskAssignees()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.good) {
          this.allUsers = [...response.data].map((i) => { i.fullName = i.firstName + ' ' + i.lastName; return i; });
          if (this.f.id.value === null) {
            let dueDate = moment().add(23, 'h').add(59, 'm').add(59, 's');
            this.addTaskForm.patchValue({
              assignedUserId: util.getActiveUser().id,
              dueDate: dueDate.toDate(),
            });
          }
        }
      });
    
  }

  getCalendarUrl() {
    var clientName = this.allClients.find(x => x.id === this.addTaskForm.value.clientId)!.fullName.replace(/\s+/g, '+');
    var campaignName = this.fiteredCampaigns.find(x => x.id === this.addTaskForm.value.campaignId)!.name.replace(/\s+/g, '+');
    var calendarDates = `${this.addTaskForm.value.dueDate.getFullYear()}${('0' + (this.addTaskForm.value.dueDate.getMonth() + 1)).slice(-2)}${('0' + this.addTaskForm.value.dueDate.getDate()).slice(-2)}T120000`;
    var calendarUrl = this.calendarRoot + this.addTaskForm.value.title.replace(/\s+/g, '+') + '+with+' + clientName + '+for+' + campaignName + this.calendarDetailsLabel + this.addTaskForm.value.description.replace(/\s+/g, '+') + this.calendarDatesLabel + calendarDates + '/' + calendarDates;
    window.open(calendarUrl, '_blank');
  }

  get f() { return this.addTaskForm.controls; }

  updateData(data: IClient) {
    this.addTaskForm.patchValue(data);
  }

  ngOnInit() {
    this.f.clientId.valueChanges.subscribe(value => {
      if (value == null || value === -1)
        this.fiteredCampaigns = [...this.allCampaigns];
      else
        this.dataFeedService.getAllClientCampaigns([value])
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            if (response.good) {
              this.fiteredCampaigns = _.sortBy([...response.data], 'name');
              if (!this.fiteredCampaigns.find(x => x.id === this.addTaskForm.controls["campaignId"].value))
                this.addTaskForm.patchValue({ campaignId: null });
            }
          });
    });
    this.f.clientId.valueChanges
      .subscribe(val => {
        if (!!val && val === -1)
          this.allCampaigns = [];
      })
  }

  deleteTask() {
    Swal.fire({
      title: 'Are you sure you want to delete this task?',
      text: "You won't be able to revert this action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) { 
        this.oDeleteClicked.emit(this.f.id.value);
      }
    });
  }

  markAsComplete() {
    this.oMarkAsCompleteClicked.emit({ id: this.f.id.value, isCompleted: true, completedOn: moment().utc().toDate() });
  }

  onSubmit() {
    this.submitted = true;
    if (this.addTaskForm.invalid)
      return;
    if (this.f.id.value === null || this.f.id.value === -1)
      this.f.id.setValue(createGuid())

    if (this.f.campaignId.value === -1)
      this.f.campaignId.setValue(null);
    if (this.f.clientId.value === -1)
      this.f.clientId.setValue(null);

    let formValues: ITask = this.addTaskForm.value;

    formValues = this.trimObjectStrings(formValues);

    formValues.dueDate = Utility.getDateFromDateTime(formValues.dueDate);
    this.oSaveClicked.emit(formValues);
  }

  // This function will recursively iterate through the object
  trimObjectStrings(obj: any): any {
    // Iterate over each property of the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (typeof value === 'string') {
          // Trim the string if the value is a string
          obj[key] = value.trim();
        } else if (typeof value === 'object' && value !== null) {
          // Recursively call the function if the value is an object (or array)
          this.trimObjectStrings(value);
        }
      }
    }
    return obj;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}