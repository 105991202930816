import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IPermission } from '../../../core/models/auth.models';
import { ICampaign } from '../../../core/models/campaigns.model';
import { ILead } from '../../../core/models/lead.models';
import { PaymentMethodApiService } from '../../../pages/my-account/payment-method/payment-method.apiservice';
import { AppService } from '../../../store/app.service';
import { PAGE_NAMES } from '../../../core/helpers/constants';

@Component({
  selector: 'modal-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss']
})
export class AddPaymentMethodModal implements OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();
  doWhat: IPermission = {} as IPermission;
  allCampaigns: Array<ICampaign> = new Array<ICampaign>();

  addForm!: FormGroup;
  submitted = false;
  id: string = '';
  allClients: Array<any> = new Array<any>();
  @Input() subscriptionId = '';

  @Input() set iId(value: string) {
    this.id = value;
    if (value) {
      this.loadDetails();
    }
  }
  expiryControl = new FormControl();

  constructor(
    private appService: AppService,
    private paymentMethodApiService: PaymentMethodApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {

    this.addForm = this.formBuilder.group({
      id: [null],
      nickname: [null, [Validators.required]],
      clientId: [null, [Validators.required]],
      cardNumber: [null, [Validators.required]],
      cvv: [null, [Validators.required]],
      expiry: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      address: [null, []],
      city: [null],
      state: [''],
      zip: ['']
    });

    this.appService.getAllClientsFeed$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.allClients = [...response].map(i => { i.fullName = i.firstName + ' ' + i.lastName; return i; });
        if (this.allClients.length === 0)
          this.appService.fetchAllClientsFeed();
      });
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Payment Methods');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.PaymentMethods);
  }

  get f() { return this.addForm.controls; }

  loadDetails() {
    this.addForm.patchValue({ 'id': this.id });
    this.paymentMethodApiService.getPaymentMethod(this.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.addForm.patchValue(response.paymentMethod);
      });
      this.addForm.controls["cvv"].clearValidators();
      this.addForm.controls["cvv"].updateValueAndValidity();
  }

  updateData(data: ILead) {
    this.addForm.patchValue(data);
  }

  ngAfterViewInit() {

  }

  onSubmit() {
    this.submitted = true;
    if (this.addForm.invalid)
      return;
    var request = {
      ...this.addForm.getRawValue()
    };

    request = this.trimObjectStrings(request);

    request.subscriptionId = this.subscriptionId;
    request.id = this.id;
    this.paymentMethodApiService.addEdit(request).subscribe((response: any) => {
      if (response.good) {
        this.activeModal.close();
        this.oSaveClicked.emit(response);
      }
    });
  }

  // This function will recursively iterate through the object
  trimObjectStrings(obj: any): any {
    // Iterate over each property of the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (typeof value === 'string') {
          // Trim the string if the value is a string
          obj[key] = value.trim();
        } else if (typeof value === 'object' && value !== null) {
          // Recursively call the function if the value is an object (or array)
          this.trimObjectStrings(value);
        }
      }
    }
    return obj;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
