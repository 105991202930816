<div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div class="polygon" style="position: relative; top: 1px;">
            <h2 style="margin-bottom: 0px !important;">TO-DO</h2>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
            <input type="checkbox" value="Show Only My Tasks" (change)="showCompletedTasks();">
            <label style="margin-left: 8px !important; margin-bottom: 0px !important; margin-right: 8px !important;">
                Show Completed Tasks
            </label>
            <input type="checkbox" id="showMyTasksCheckbox" [(ngModel)]="showOnlyMyTasks" (change)="onShowMyTasksChange()" value="Show Only My Tasks">
            <label style="margin-left: 8px !important; margin-bottom: 0px !important;" for="showMyTasksCheckbox">
                Show Only My Tasks
            </label>
            <label style="margin-bottom: 0px !important; padding-right: 8px; margin-left: 32px !important;">
                Show Only Tasks With Due Date:
            </label>
            <select [(ngModel)]="showTasksDue" (change)="onDueDateFilterChange()" style="border-radius: 4px;">
                <option>All</option>
                <option>Overdue</option>
                <option>Today</option>
                <option>Upcoming</option>
            </select>
    </div>
</div>
<div *ngIf="showCompleted" style="border: none !important;">
    <app-tasks-datatable
        [iSortColumn]="'title'"
        [iShowActions]="userRoles.includes(ROLES.Admin.Code)"
        (oRowClick)="editRow($event)"
        [iUserRoles]="userRoles"
        (oCBClick)="onCheckBoxChange($event)"
        [iHideHeaderActions]="true"
        [iColumns]="columns"
        (oDeleteRows)="deleteRows($event)"
        (oEditRow)="editRow($event)"
        [iData$]="todoTasksData"
        [iIsServerLoading]="true"
        [iTotalCount]="dataTotalCount"
        (oLoadDataFromServer)="onLoadingData($event)">
    </app-tasks-datatable>
</div>
<div *ngIf="!showCompleted" style="border: none !important;">
    <app-tasks-datatable
        [iSortColumn]="'title'"
        [iShowActions]="userRoles.includes(ROLES.Admin.Code)"
        (oRowClick)="editRow($event)"
        [iUserRoles]="userRoles"
        (oCBClick)="onCheckBoxChange($event)"
        [iHideHeaderActions]="true"
        [iColumns]="columns"
        (oDeleteRows)="deleteRows($event)"
        (oEditRow)="editRow($event)"
        [iData$]="todoTasksData"
        [iIsServerLoading]="true"
        [iTotalCount]="dataTotalCount"
        (oLoadDataFromServer)="onLoadingData($event)">
    </app-tasks-datatable>
</div>