import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../store/app.service';
import { ILead } from '../../../core/models/lead.models';
import { TasksService } from '../../tasks/tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ITask } from '../../../core/models/tasks.models';
import { ClientsApiService } from '../clients.apiservice';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IClient } from '../../../core/models/clients.models';
import { IPermission } from '../../../core/models/auth.models';
import { TasksApiService } from '../../tasks/tasks.apiservice';
import { LeadsApiService } from '../../leads/leads.apiservice';
import { getRolesForPage } from '../../../core/helpers/utility';
import { ICampaign } from '../../../core/models/campaigns.model';
import { ReportsApiService } from '../../reports/reports.apiservice';
import { RefreshSignals } from '../../../layouts/sidebar/menu.model';
import { UtilityService } from '../../../core/helpers/utility.service';
import { IReportingConfig } from '../../../core/models/reporting.models';
import { CampaignsApiService } from '../../campaigns/campaigns.apiservice';
import { PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { SubscriptionApiService } from '../../subscriptions/subscription.apiservice';
import { IColumn, LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { ClientReportingUpdateModalComponent } from '../client-reporting-update/client-reporting-update.component';
import { OrdersApiService } from '../../orders/orders.apiservice';

@Component({
  selector: 'app-clients',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})

export class ClientDetailsComponent implements OnInit, OnDestroy {

  savedSearchId!: string;
  private loadEvent!: LoadDataFromServer;

  doWhat: IPermission = {} as IPermission;

  campaignColumns = [
    { title: 'Received At', field: 'createdAt', sortable: true, searchable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY HH:mm', true] },
    { title: 'First Name', field: 'firstName', sortable: true, searchable: true },
    { title: 'Last Name', field: 'lastName', sortable: true, searchable: true },
    { title: 'Email', field: 'email', sortable: true, searchable: true },
    { title: 'Phone', field: 'phone', sortable: true, searchable: true },
    { title: 'Campaign', field: 'campaignName', sortable: true, searchable: true },
    { title: 'Referal Source', field: 'referralSource', sortable: true, searchable: true },
  ]

  orderColumns = [
    { title: 'Id', field: 'numericId', sortable: true, searchable: true },
    { title: 'From', field: 'startDate', sortable: true, searchable: true },
    { title: 'To', field: 'endDate', sortable: true, searchable: true },
    { title: 'Amount', field: 'amount', sortable: true, searchable: true, pipe: 'CurrencyPipe' },
    { title: 'Campaign', field: 'campaignName', sortable: true, searchable: true },
  ]

  columnsCampaign = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    { title: 'Client', field: 'clientName', sortable: true, searchable: true },
    { title: 'Platform', field: 'platform', sortable: true, searchable: true },
    { title: 'DMS', field: 'dmsName', sortable: true, searchable: true },
    { title: 'Stage', field: 'stageId', sortable: true, pipe: 'P_CampaignStageTitle', searchable: true },
    { title: 'Active Subscription', field: 'subscriptionActive', pipe: 'P_Boolean', searchable: true },
  ];

  reportingConfigTableColumns = [
    { title: 'Report Type', field: 'reportType' },
    { title: 'Is Rollup', field: 'isRollup' },
    { title: 'Platforms', field: 'platforms' },
    { title: 'Campaigns', field: 'campaigns' },
    { title: 'To Email', field: 'toEmail' },
  ];

  reportingConfigTableData = new Array<{ reportingConfig: IReportingConfig, id: string, reportType: string, isRollup: boolean, platforms: string, campaigns: string, toEmail: string }>();

  showTasks: boolean = true;
  showCompletedTasks: boolean = false;
  showOnlyMyTasks: boolean = true;
  isAdmin: boolean = false;
  isExternal: boolean = false;
  
  userId: string = '';
  subscriptions = new Array<any>();  
  permLeadsData = new Array<ILead>();
  leadsData = new Array<ILead>();
  ordersData = new Array<any>();
  
  active = 1;
  dataTotalCount: number = 0;
  leadsTotalCount: number = 0;

  public ROLES = ROLES;

  columnsTasks = new Array<IColumn>();
  campaignsData = new Array<ICampaign>();
  completedTasksColumns: Array<IColumn> = []
  tasksData: Array<ITask> = new Array<ITask>();
  completedTasksData: Array<ITask> = new Array<ITask>();
  permTasksData: Array<ITask> = new Array<ITask>();
  reportingConfigData: Array<IReportingConfig> = [];
  public userRoles: Array<number> = [];
  roleOptions: any = {};

  clientData: IClient = {} as IClient;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  checkingOrders: Array<any> = new Array<any>();
  clientId: string = '';

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public uService: UtilityService,
    private router: Router,
    private taskApiService: TasksApiService,
    private tasksService: TasksService,
    private appService: AppService,
    private clientService: ClientsApiService,
    private campaignsService: CampaignsApiService,
    private leadsApi: LeadsApiService,
    private reportsService: ReportsApiService,
    private subscriptionService: SubscriptionApiService,
    private ordersApiService: OrdersApiService) {

      this.userRoles = [...this.uService.getUserRoles()];

      this.columnsTasks.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
      this.columnsTasks.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
      this.columnsTasks.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
      this.columnsTasks.push({ title: 'Due Date', field: 'dueDate', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY'] } as IColumn);
      this.columnsTasks.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, visible: (this.userRoles.includes(ROLES.Admin.Code) || this.userRoles.includes(ROLES.TechServices.Code)) || this.userRoles.includes(ROLES.SalesRep.Code) } as IColumn);

      this.completedTasksColumns.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
      this.completedTasksColumns.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
      this.completedTasksColumns.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
      this.completedTasksColumns.push({ title: 'Completed On', field: 'completedOn', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['YYYY/MM/DD HH:mm', true] } as IColumn);
      this.completedTasksColumns.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, visible: (this.userRoles.includes(ROLES.Admin.Code) || this.userRoles.includes(ROLES.TechServices.Code) || this.userRoles.includes(ROLES.SalesRep.Code)) } as IColumn);
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Client');
    this.appService.setRefreshSignal$(PAGE_NAMES.ClientDetails, '');

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        if (u.id !== undefined) {
          this.userId = u.id;
        }
        this.isExternal = u.type == ROLES_TYPE.External
        var r = getRolesForPage(u.roles, PAGE_NAMES.Clients);
        this.isAdmin = r.isAdmin;
        if (this.isAdmin) {
          this.showOnlyMyTasks = false;
        }
        if (r.options) {
          this.roleOptions = JSON.parse(r.options!);
        }
      });

    let id = this.route.snapshot.paramMap.get('id');
    this.clientId = id!;

    if (id) {
      this.clientService.getClientDetails(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.clientData = response.data;
        });
      this.campaignsService.getCampaignsByClient(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.campaignsData = response.data
        });
      this.taskApiService.getTasksByClient(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.tasksData = [...response.data.filter((x: ITask) => (x.isCompleted === null || x.isCompleted === false) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true))];
          this.permTasksData = [...response.data];
        });
      this.getReportingConfigTableData(id);
    }

    const sub = this.appService.getRefreshSignal$(PAGE_NAMES.ClientDetails);
    if (sub !== null) {
      sub.pipe(takeUntil(this.destroyed$))
        .subscribe((r: RefreshSignals) => {
          this.doRefresh(r.id);
        })
    }

    this.configureTasksService();
  }

  loadOrders() {
    this.checkingOrders = [];
    this.ordersApiService.getAllOrders()
      .pipe()
      .subscribe((response: any) => {
        this.ordersData = response.orders;
        this.dataTotalCount = this.ordersData.length;
        this.subscriptionService.getAllSubscriptions(null, this.clientId!)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            if (response.subscriptions) {
              this.subscriptions = response.subscriptions.map((x:any) => {x.dms = x.campaigns[0].dms; x.salesrep = x.campaigns[0].salesRep; return x});
              this.subscriptions.forEach((subscription: any) => {
                var orders = this.ordersData.filter((order: any) => order.subscriptionNumericId === subscription.numericId);
                orders.forEach((order: any) => {
                  order.clientId = subscription.campaigns[0].clientId;
                  this.checkingOrders.push(order);
                });
              });
            }
          });
    });
  }

  filterLeads() {
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Leads);
    this.leadsApi.getAllLeads({})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.leads) {
          this.leadsData = [...response.leads];
          var newArray = new Array<string>();
          this.campaignsData.forEach((campaign: ICampaign) => {newArray.push(campaign.id)});
          this.permLeadsData = this.leadsData.filter((x: ILead) => newArray.includes(x.campaignId));
          this.leadsData = this.permLeadsData;
          this.leadsTotalCount = this.leadsData.length;
        }
      });
  }

  loadTasksData() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.taskApiService.getTasksByClient(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.tasksData = [...response.data.filter((x: ITask) => (x.isCompleted === null || x.isCompleted === false) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true))];
          this.completedTasksData = [...response.data.filter((x: ITask) => (x.isCompleted && x.isCompleted === true) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true))];
        });
    }
  }

  configureTasksService() {
    this.tasksService.onSave.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadTasksData();
        }
      });
    this.tasksService.onDelete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadTasksData();
        }
      });
    this.tasksService.onMarkAsComplete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadTasksData();
        }
      });
  }

  doRefresh(id: string) {
    if (id !== "")
      this.clientService.getClientDetails(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.clientData = response.data;
        });
  }

  onTaskRowClick(evt: any) {
    this.tasksService.openEditTaskModal(evt, this.isAdmin || this.roleOptions.tasks.canEdit, this.isAdmin || this.roleOptions.tasks.canDelete);
  }

  onCampaignsRowClick(evt: any) {
    this.router.navigate(['/pages/campaign-details', evt.id]);
  }

  getCampaignRowLink(row: any) {
    return `/pages/campaign-details/${row.id}`;
  }

  getReportingConfigTableData(clientId: string): void {
    this.reportingConfigTableData = [];
    this.reportingConfigData = [];

    if (clientId) {
      this.reportsService
        .getReportingConfigsByClient(clientId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          let configs = new Array<any>();
          this.reportingConfigData = response.data;

          this.reportingConfigData
            .forEach((reportingConfig: any) => {
              let platformsAll = reportingConfig.platforms as Array<string>;
              let distinctPlatforms = platformsAll.filter((v, i, a) => a.indexOf(v) === i);
              let groupedPlatforms = this.groupBy(platformsAll, (c: any) => c);

              let platforms = distinctPlatforms.map(m => `${m} (${groupedPlatforms[m].length})`).join(', \r\n');

              let c = {
                reportingConfig: reportingConfig,
                id: reportingConfig.id /* Need this column as part of table so that when on delete we can access the list of IDs that were selected*/,
                reportType: reportingConfig.reportType,
                isRollup: reportingConfig.isRollup === true ? 'yes' : 'no',
                platforms: platforms,
                campaigns: reportingConfig.campaigns.filter((n: any, i: any) => reportingConfig.campaigns.indexOf(n) === i).join(', \r\n'),
                toEmail: reportingConfig.toEmail
              };
              configs.push(c);
            })
          this.reportingConfigTableData = configs;
        });
    }
  }

  groupBy(arr: Array<any>, func: { (c: any): any; (arg0: any): any; }) {
    return arr.reduce((r: { [x: string]: any[]; }, v: any, i: any, a: any, k = func(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  addNewReportingConfig() {
    let reportingConfig = {} as IReportingConfig;
    reportingConfig.isRollup = false;
    reportingConfig.client = this.clientData;
    reportingConfig.clientId = this.clientData.id;
    reportingConfig.toEmail = this.clientData.email;

    this.openReportingConfigModal(reportingConfig);
  }

  editReportingConfigRow(evt: any) {
    this.openReportingConfigModal(evt.reportingConfig);
  }

  isReportingConfig(res: Array<string> | IReportingConfig): res is IReportingConfig {
    return (res as IReportingConfig).id !== undefined;
  }

  deleteReportingConfigRows(evt: Array<string> | IReportingConfig) {
    let param: Array<string> = [];

    if (this.isReportingConfig(evt)) {
      param = [(evt as IReportingConfig).id];
    }
    else {
      param = evt as Array<string>;
    }

    this.reportsService.deleteReportingConfig(param)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.getReportingConfigTableData(this.clientData.id);
      });
  }

  openReportingConfigModal(reportingConfig: IReportingConfig) {
    const dialogRef = this.modalService.open(ClientReportingUpdateModalComponent, { size: 'lg' });
    dialogRef.componentInstance.CampaignsData = this.campaignsData;
    dialogRef.componentInstance.ReportingConfig = reportingConfig;

    const dialogSubmitSubscription = dialogRef.componentInstance.oSaveClicked.subscribe((r: any) => {
      this.reportsService.saveReportingConfig(r)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          dialogRef.dismiss();
          this.getReportingConfigTableData(this.clientData.id);
        })

      dialogSubmitSubscription.unsubscribe();
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onAddCampaign() {
    this.router.navigate(['pages/edit-campaign/']);
  }

  onAddTask() {
    this.tasksService.openEditTaskModal({ id: null, clientId: this.clientData.id }, this.isAdmin || this.roleOptions.tasks.canEdit, this.isAdmin || this.roleOptions.tasks.canDelete);
  }

  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;

    if ((event.search != "") && (event.search != null)) {
      this.leadsData = this.permLeadsData.filter((x: ILead) => x.firstName.toLowerCase().includes(event.search.toLowerCase()) || x.lastName.toLowerCase().includes(event.search.toLowerCase()) || x.email.toLowerCase().includes(event.search.toLowerCase()) || x.phone.toLowerCase().includes(event.search.toLowerCase()) || x.campaignName.toLowerCase().includes(event.search.toLowerCase()) || x.referralSource.toLowerCase().includes(event.search.toLowerCase()));
      this.leadsTotalCount = this.leadsData.length;
      this.leadsData = this.leadsData.slice((event.pageNumber - 1) * Number(event.pageSize), event.pageNumber * Number(event.pageSize));
    }
    else {
      this.leadsData = this.permLeadsData.slice((event.pageNumber - 1) * Number(event.pageSize), event.pageNumber * Number(event.pageSize));
      this.leadsTotalCount = this.permLeadsData.length;
    }
  }

  onDelete(event: any) {
    this.leadsApi.deleteLead(event)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.appService.fetchAllLeads({
          SavedSearchId: this.savedSearchId,
          ...this.loadEvent
        });
      });
  }

  getRowLink(row: any) {
    return `/pages/lead-details/${row.id}`;
  }

  onClick(event: any) {
    this.router.navigate(['/pages/orders/details', event.id]);
  }

  changeShowTasks() {
    if (this.showCompletedTasks) {
      this.showTasks = true;
      this.showCompletedTasks = false;
    }
  }
  
  changeShowCompletedTasks() {
    if (this.showTasks) {
      this.completedTasksData = this.permTasksData.filter((x: ITask) => (x.isCompleted && x.isCompleted === true) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true));
      this.showTasks = false;
      this.showCompletedTasks = true;
    }
  }

  onShowMyTasksChange() {
    this.loadTasksData();
  }
}
